import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import { Footer } from '../../components/Footer'
import { Header } from '../../components/Header'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import Faq from '@solid-ui-blocks/Faq/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import GatsbyConfig from '../../../gatsby-config';

const FAQPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout {...props}>
      <Seo
        title='FAQ'
        siteUrl={GatsbyConfig.siteMetadata.siteUrl}
        thumbnail={{
          hero: {
            src: '/srm_acronym_logo.png'
          }
        }}
      />
      {/* Blocks */}
      <Header />
      <Divider space='6' />
      <Container variant='narrow'>
        <Faq content={content['digital-marketing-faq']} />
      </Container>
      <Divider space='5' />
      <Container variant='narrow'>
        <Faq content={content['simple-rm-faq']} />
      </Container>
      <Divider space='5' />
      <Container variant='narrow'>
        <Faq content={content['advanced-simple-rm-faq']} />
      </Container>
      <Divider space='5' />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query faqBlockContent {
    allBlockContent(
      filter: { page: { in: ["site/faq"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default FAQPage
